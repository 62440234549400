import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
	name: 'timeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
	transform(value: DateTime | string): string {
		if (!value) {
			return '';
		}

		const dateTime =
			typeof value === 'string' ? DateTime.fromISO(value) : value;

		// Check the difference between the current date and the date passed in
		const diff = dateTime.diffNow().shiftTo('seconds').toObject();

		// If the difference is less than 5 seconds, return 'just now'
		if (Math.abs(diff.seconds) < 5) {
			return 'just now';
		}
		// Otherwise, return the relative time
		else {
			return dateTime.toRelative({ locale: 'en' });
		}
	}
}
