import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AbilityModule } from '@casl/angular';
import { FuseCardModule } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { CanDeactivateGuard } from 'app/core/guards/can-deactivate.guard';
import { TableSearchBarComponent } from 'app/shared/components/table-search-bar/table-search-bar.component';

import { ActionButtonComponent } from './components/buttons/main-buttons/action-button/action-button.component';
import { PlainButtonComponent } from './components/buttons/main-buttons/plain-button/plain-button.component';
import { PrimaryButtonComponent } from './components/buttons/main-buttons/primary-button/primary-button.component';
import { WarnButtonComponent } from './components/buttons/main-buttons/warn-button/warn-button.component';
import { SharePageButtonComponent } from './components/buttons/other-buttons/share-page/share-page-button/share-page-button.component';
import { SharePageMenuItemComponent } from './components/buttons/other-buttons/share-page/share-page-menu-item/share-page-menu-item.component';
import { ColorBubbleComponent } from './components/color-bubble/color-bubble.component';
import { DescriptionCardComponent } from './components/description-card/description-card.component';
import { CountriesDropdownComponent } from './components/dropdowns/countries-dropdown/countries-dropdown.component';
import { FilterDropdownComponent } from './components/dropdowns/filter-dropdown/filter-dropdown.component';
import { FilterMultiselectDropdownComponent } from './components/dropdowns/filter-multiselect-dropdown/filter-multiselect-dropdown.component';
import { GetTranslationKeyForValuePipe } from './components/dropdowns/filter-multiselect-dropdown/get-translation-key-for-value.pipe';
import { FloatingMenuComponent } from './components/floating-menu/floating-menu.component';
import { GenericPillComponent } from './components/generic-pill/generic-pill.component';
import { RecordHeaderComponent } from './components/headers/record-header/record-header.component';
import { MenuItemComponent } from './components/list-items/menu-item/menu-item.component';
import { TimeMachineComponent } from './components/time-machine/time-machine.component';
import { TimeTravelingBadgeComponent } from './components/time-traveling-badge/time-traveling-badge.component';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { RemoveSuffixPipe } from './pipes/remove-suffix.pipe';
import { TimeFromNowPipe } from './pipes/time-from-now.pipe';
import { WidgetsModule } from './widgets/widgets.module';
import { RecordHeaderMenuComponent } from './components/headers/record-header/components/record-header-menu/record-header-menu.component';
import { UltraButtonComponent } from './components/ultramarine-ui/ultra-button/ultra-button.component';
import { UltraPrimaryButtonComponent } from './components/ultramarine-ui/ultra-primary/ultra-primary.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressIndicatorComponent } from './components/headers/record-header/components/progress-indicator/progress-indicator.component';
import { StatusPillComponent } from './components/status-pill/status-pill.component';

@NgModule({
	declarations: [
		// Pipes
		RemoveSuffixPipe,
		TimeFromNowPipe,
		CountryNamePipe,
		//Components
		DescriptionCardComponent,
		SharePageButtonComponent,
		SharePageMenuItemComponent,
		TableSearchBarComponent,
		RecordHeaderComponent,
		PrimaryButtonComponent,
		FilterDropdownComponent,
		CountriesDropdownComponent,
		FilterMultiselectDropdownComponent,
		WarnButtonComponent,
		ActionButtonComponent,
		PlainButtonComponent,
		MenuItemComponent,
	],
	exports: [
		//Components
		DescriptionCardComponent,
		SharePageButtonComponent,
		SharePageMenuItemComponent,
		TableSearchBarComponent,
		RecordHeaderComponent,
		PrimaryButtonComponent,
		FilterDropdownComponent,
		CountriesDropdownComponent,
		FilterMultiselectDropdownComponent,
		WarnButtonComponent,
		ActionButtonComponent,
		PlainButtonComponent,
		MenuItemComponent,
		// Modules
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatProgressSpinnerModule,
		AbilityModule,
		// Pipes
		RemoveSuffixPipe,
		TimeFromNowPipe,
		CountryNamePipe,
		UltraPrimaryButtonComponent,
	],
	providers: [CanDeactivateGuard],
	imports: [
		// Modules
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		WidgetsModule,
		RouterModule,
		FuseCardModule,
		AbilityModule,
		TranslocoModule,
		// Material
		MatTabsModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatSelectModule,
		MatTableModule,
		MatPaginatorModule,
		MatDividerModule,
		MatProgressSpinnerModule,
		TranslocoModule,
		GetTranslationKeyForValuePipe,
		GenericPillComponent,
		ColorBubbleComponent,
		TimeMachineComponent,
		TimeTravelingBadgeComponent,
		AsyncPipe,
		RecordHeaderMenuComponent,
		UltraButtonComponent,
		UltraPrimaryButtonComponent,
		ProgressIndicatorComponent,
		FloatingMenuComponent,
		StatusPillComponent,
	],
})
export class SharedModule {}
