import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { SelectItem } from 'app/shared/types/select-item.type';

@Component({
	selector: 'filter-multiselect-dropdown',
	templateUrl: './filter-multiselect-dropdown.component.html',
	styleUrls: ['./filter-multiselect-dropdown.component.scss'],
	host: {
		class: 'w-full sm:w-auto',
	},
})
export class FilterMultiselectDropdownComponent<T, K> implements OnInit {
	@Input({ required: true }) filterTitle: string;
	@Input({ required: true }) filterOptions: SelectItem<T>[];
	@Input() public queryParams: string[];
	@Input() public navigationContextSelections: K[];
	@Output() onFilterSelected: EventEmitter<T[]> = new EventEmitter<T[]>();
	public filtersSelected: SelectItem<T>[] = [];

	public ngOnInit() {
		// if (
		// 	this.navigationContextSelections &&
		// 	this.navigationContextSelections.length > 0
		// ) {
		// 	console.log(this.navigationContextSelections);
		// 	console.log(this.filterOptions);
		// }
		// if there are no query params there is no need to emit here
		// if (this.queryParams && this.queryParams.length > 0) {
		// 	this.filtersSelected = this.queryParams as T[]; // casting query params as T
		// 	this.onFilterSelected.emit(this.filtersSelected);
		// }
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.filterOptions || changes.navigationContextSelections) {
			if (this.filterOptions && this.navigationContextSelections) {
				// make sure that both filterOoptions and navigationContextSelections are updated
				const selections = this.filterOptions.map((item) => item.value);
				const filteredSelections = selections.filter((selection: any) => {
					return this.navigationContextSelections.includes(
						// cover both dtos and enums as selections
						selection.id || selection,
					);
				});
				const returnFilters = this.filterOptions.filter((option) =>
					filteredSelections.includes(option.value),
				);
				this.filtersSelected = returnFilters;
				this.onFilterSelected.emit(
					this.filtersSelected.map((item) => item.value),
				);
			}
		}
	}

	public onFiltersChange(selection: SelectItem<T>[]): void {
		this.filtersSelected = selection;
		this.onFilterSelected.emit(this.filtersSelected.map((item) => item.value));
	}
}
