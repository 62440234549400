import { ModulesEnum } from '../list-table/modules.enum';

export function statusToTranslation(statusValue: string, module: ModulesEnum) {
	let moduleName: string;
	// the switch case is done because of the inconsistency between  the enum values
	// with the translation keys
	switch (module) {
		case ModulesEnum.LOCATIONS: {
			moduleName = 'locations';
			break;
		}
		case ModulesEnum.STAFF: {
			moduleName = 'staff';
			break;
		}
		case ModulesEnum.CUSTOMERS: {
			moduleName = 'customers';
			break;
		}
		case ModulesEnum.SUPPLIERS: {
			moduleName = 'suppliers';
			break;
		}
		case ModulesEnum.VEHICLES: {
			moduleName = 'vehicles';
			break;
		}
		case ModulesEnum.VEHICLE_ROAD_TAXES: {
			moduleName = 'road-taxes';
			break;
		}
		case ModulesEnum.VEHICLE_ROAD_WORTHY_CHECKS: {
			moduleName = 'road-worthy-checks';
			break;
		}
		case ModulesEnum.VEHICLE_MAINTENANCES: {
			moduleName = 'maintenances';
			break;
		}
		case ModulesEnum.VEHICLE_DAMAGES: {
			moduleName = 'damages';
			break;
		}
		case ModulesEnum.VEHICLE_CATEGORIES: {
			moduleName = 'categories';
			break;
		}
		case ModulesEnum.RENTAL_RESERVATIONS: {
			moduleName = 'rental-reservations';
			break;
		}
		case ModulesEnum.RENTAL_AGREEMENTS: {
			moduleName = 'rental-agreements';
			break;
		}
		case ModulesEnum.LEASE_AGREEMENTS: {
			moduleName = 'lease-agreements';
			break;
		}
	}

	return `${moduleName}|status|${statusValue.replace(/_/g, '-')}`;
}
