<div class="ultramarine-page-header">
	<div class="flex-1 min-w-0">
		<div class="flex items-center justify-between w-full">
			<span class="flex items-center content-center">
				<a
					class="hidden sm:flex"
					*ngIf="showBackToList"
					ultra-button
					[svgIcon]="icon"
					[content]="moduleName"
					[routerLink]="'../..'">
				</a>
				<span class="mx-1"> / </span>
				<button
					ultra-button
					[content]="headerData.title"
					*ngIf="headerData.title"></button>
				<span
					*ngIf="!headerData.title && isNew"
					ultra-button
					disabled="true"
					[content]="'New Record'"></span>
				<app-status-pill
					class="flex mx-1 items-center"
					*ngIf="headerData.status && !headerData.isDraft"
					[module]="module"
					[value]="headerData.status"
					[pillColor]="headerData.color" />
				<app-generic-pill
					*ngIf="headerData.isDraft"
					class="mx-1"
					[value]="'Draft'"
					[pillColor]="'um-neutral'" />

				<button
					ultra-button
					*ngIf="!hideMenuToggle && !isNew"
					#anchor
					(click)="showHeaderMenu()"
					[svgIcon]="'more_horiz'"></button>
			</span>

			<!-- Actions -->
			<div class="flex items-center gap-2">
				<!-- <ng-container *ngIf="isEditMode && !isNew">
					<app-progress-indicator
				></app-progress-indicator>
				</ng-container> -->

				<button
					ultra-primary
					*ngIf="isEditMode"
					(click)="onSave.emit()"
					color="primary"
					[disabled]="!isSaveButtonActive"
					[svgIcon]="'check_circle'"
					[content]="'shared|record-header|save'"></button>

				<button
					*ngIf="isEditMode"
					[disabled]="!(isPresent | async) || !allowEdit"
					(click)="onCancel.emit()"
					[svgIcon]="'block'"
					class="flex"
					ultra-primary></button>

				<button
					*ngIf="!headerData.isDeleted && !isEditMode"
					[disabled]="!(isPresent | async) || !allowEdit"
					(click)="onEdit.emit()"
					color="primary"
					[svgIcon]="'edit'"
					[content]="'shared|record-header|edit'"
					ultra-primary
					class="hidden sm:flex"></button>

				<button
					ultra-primary
					[disabled]="true"
					class="hidden sm:block h-9"
					[svgIcon]="'download'"
					(click)="this.exportRecord.emit()"></button>

				<button
					ultra-primary
					class="block h-9"
					*ngIf="!headerData.isDeleted"
					[svgIcon]="'format_list_bulleted'"
					[disabled]="isNew || !allowEdit"
					(click)="onToggleSidebar.emit()"></button>
			</div>
		</div>
	</div>
</div>
<app-floating-menu
	[isMobileView]="isMobileView"
	[showEditButton]="!headerData.isDeleted"
	[showCancelButton]="!headerData.isDeleted"
	[showExportButton]="true"
	[showToggleDrawerButton]="!headerData.isDeleted"
	(editButtonClicked)="onEdit.emit()"
	(saveButtonClicked)="onSave.emit()"
	(cancelButtonClicked)="onCancel.emit()"
	(deleteButtonClicked)="onDelete.emit()"
	(exportButtonClicked)="this.exportRecord.emit()"
	(toggleDrawerClicked)="onToggleSidebar.emit()"
	[isSaveButtonActive]="isSaveButtonActive"
	[isEditMode]="isEditMode"
	[isDrawerOpened]="isDrawerOpened" />

<ng-template #templateRef>
	<app-record-header-menu
		[setHeaderData]="headerData"
		(onMarkAsReadyClicked)="onMarkAsReady.emit(void)"
		(onDeleteClicked)="onDelete.emit(void)" />
</ng-template>
