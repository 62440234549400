<mat-form-field
	class="fuse-mat-dense fuse-mat-rounded sm:min-w-50 w-full app-mat-select-placeholder"
	[subscriptSizing]="'dynamic'">
	<mat-label>{{ filterTitle }}</mat-label>
	<mat-select
		[placeholder]="'shared|all-filters-placeholder' | transloco"
		[ngModel]="filtersSelected"
		(ngModelChange)="onFiltersChange($event)"
		multiple>
		<!-- If we have multiselect enabled show custom trigger -->
		<mat-select-trigger>
			{{
				filtersSelected.length > 0 ? (filtersSelected[0].name | titlecase) : ''
			}}
			<span
				class="text-hint text-sm"
				*ngIf="(filtersSelected?.length || 0) > 1">
				(+{{ (filtersSelected?.length || 0) - 1 }}
				{{
					filtersSelected?.length === 2
						? ('other-translation-key' | transloco)
						: ('others-translation-key' | transloco)
				}})
			</span>
		</mat-select-trigger>
		<mat-option
			*ngFor="let filterOption of filterOptions"
			[value]="filterOption.value">
			{{ filterOption.displayValue | titlecase }}
		</mat-option>
	</mat-select>
</mat-form-field>
