import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'app/shared/types/select-item.type';
@Component({
	selector: 'filter-dropdown',
	templateUrl: './filter-dropdown.component.html',
	styleUrls: ['./filter-dropdown.component.scss'],
	host: {
		class: 'w-full sm:w-auto',
	},
})
export class FilterDropdownComponent<T> implements OnInit {
	@Input({ required: true }) filterTitle: string = '';
	@Input({ required: true }) filterSelected: T;
	@Input({ required: true }) filterOptions: SelectItem<T>[] = [];
	@Input() public queryParam: string;
	@Output() onFilterSelected: EventEmitter<T> = new EventEmitter<T>();

	public ngOnInit(): void {
		if (this.queryParam) {
			this.filterSelected = this.queryParam as T; // casting the query params to T
			this.onFilterSelected.emit(this.filterSelected);
		}
	}

	public onFiltersChange(selection: T): void {
		this.filterSelected = selection;
		this.onFilterSelected.emit(this.filterSelected);
	}
}
