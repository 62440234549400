import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'app/shared/types/select-item.type';
@Component({
	selector: 'countries-dropdown',
	templateUrl: './countries-dropdown.component.html',
	host: {
		class: 'w-full sm:w-auto',
	},
})
export class CountriesDropdownComponent implements OnInit {
	// --------------------------------------------------------------------------
	// @ Input
	// --------------------------------------------------------------------------
	@Input({ required: true }) filterTitle: string = '';
	@Input({ required: true }) filtersSelected: any[] = [];
	@Input({ required: true }) filterOptions: SelectItem<any>[] = [];
	@Input() queryParams: number[];

	// --------------------------------------------------------------------------
	// @ Output
	// --------------------------------------------------------------------------
	@Output() onFilterSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

	public ngOnInit(): void {
		if (this.queryParams) {
			const queryCountries = [];
			this.filterOptions.forEach((item) => {
				if (this.queryParams.some((opt) => opt === item.value.id)) {
					queryCountries.push(item.value);
				}
			});
			this.filtersSelected = queryCountries;
			this.onFilterSelected.emit(this.filtersSelected);
		}
	}

	public onFiltersChange(selection: any[]): void {
		this.filtersSelected = selection;
		this.onFilterSelected.emit(this.filtersSelected);
	}
}
