import { NgIf } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
	selector: 'button[ultra-button], span[ultra-button], a[ultra-button]',
	templateUrl: 'ultra-button.component.html',
	standalone: true,
	imports: [MatIconModule, NgIf, MatTooltipModule, TranslocoModule],
})
export class UltraButtonComponent {
	@Input() disabled: boolean | null = null;
	@Input() svgIcon?: string;
	@Input() content?: string;

	private isFocused = false;
	@HostListener('focus', ['$event'])
	onFocus(event: FocusEvent) {
		this.isFocused = true;
	}

	@HostListener('blur', ['$event'])
	onBlur(event: FocusEvent) {
		this.isFocused = false;
	}

	@HostBinding('attr.disabled') get isDisabled() {
		return this.disabled === true ? '' : null;
	}
	// Set host classes conditionally based on the disabled state
	@HostBinding('class') get className(): string {
		const baseClasses =
			'rounded-md h-fit flex px-2 py-1.5 justify-center gap-1 font-semibold';
		const enabledClasses = 'cursor-pointer hover:bg-hover';
		const disabledClasses = 'cursor-not-allowed text-gray-500';

		return `${baseClasses} ${this.disabled ? disabledClasses : enabledClasses}  ${this.isFocused ? 'focused' : ''}`;
	}
}
