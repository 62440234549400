import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { FuseLoadingService } from '../../../@fuse/services/loading';
import { UltramarineApiService } from '../../core/api/ultramarine-api.service';
import { AuthService } from '../../core/auth/auth.service';
import { AppConfigService } from '../../core/config/app.config.service';
import { SettingsService } from '../../modules/admin/settings/services/settings.service';
import { AlertsService } from '../../modules/alerts/services/alerts.service';
import { ErrorsService } from '../../modules/errors/services/errors.service';

import { ActionBarMediatorService } from './action-bar-mediator.service';
import { DialogsService } from './dialogs.service';
import { NavigationContextService } from './navigation-context.service';
import { QueryParamsStateService } from './query-params/query-params-state.service';
import { TimeMachineService } from '../components/time-machine/time-machine.service';
import { AuthzService } from './authz.service';
import { PortalService } from './portal.service';
import { MediaWatcherService } from './media-watcher.service';

@Injectable({ providedIn: 'root' })
export class AppContextService {
	constructor(
		public logger: NGXLogger,
		public alertsService: AlertsService,
		public settingsService: SettingsService,
		public mediaWatcherService: MediaWatcherService,
		public dialogsService: DialogsService,
		public router: Router,
		public actionBarMediator: ActionBarMediatorService,
		public auth: AuthService,
		public errorService: ErrorsService,
		public apiService: UltramarineApiService,
		public loaderService: FuseLoadingService,
		public appConfig: AppConfigService,
		public queryParamsState: QueryParamsStateService,
		public navContext: NavigationContextService,
		public timeMachineService: TimeMachineService,
		public authzService: AuthzService,
		public portalService: PortalService,
	) {}
}
