import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { RecordColorEnum } from 'ultramarine-shared';

@Component({
	selector: 'app-generic-pill',
	standalone: true,
	templateUrl: 'generic-pill.component.html',
	imports: [NgClass, MatTooltipModule, TranslocoModule],
})
export class GenericPillComponent {
	public colors = RecordColorEnum;
	@Input() pillColor: RecordColorEnum;
	@Input({ required: true }) value: string | number;
	@Input() tooltip: string;
	@Input() svg: string;

	isValidColor(color: RecordColorEnum): boolean {
		const validColors = [
			this.colors.GRAY,
			this.colors.RED,
			this.colors.ORANGE,
			this.colors.AMBER,
			this.colors.YELLOW,
			this.colors.LIME,
			this.colors.GREEN,
			this.colors.EMERALD,
			this.colors.TEAL,
			this.colors.CYAN,
			this.colors.SKY,
			this.colors.BLUE,
			this.colors.INDIGO,
			this.colors.VIOLET,
			this.colors.PURPLE,
			this.colors.FUCHSIA,
			this.colors.PINK,
			this.colors.ROSE,
			this.colors.SLATE,
			this.colors.ZINC,
			this.colors.STONE,
			this.colors.NEUTRAL,

			this.colors.DEFAULT,
		];

		return validColors.includes(color);
	}
}
