<div @fadeIn class="bg-default border rounded-md py-1">
	<span ultra-menu-item-wrapper>
		<button
			ultra-menu-item
			[disabled]="!headerData.isDraft"
			[content]="'shared|record-header-menu|mark-as-ready'"
			[iconClass]="'text-green-600'"
			[svgIcon]="'check'"
			(click)="onMarkAsReadyClicked.emit()"></button>
	</span>
	<span ultra-menu-item-wrapper>
		<button
			[disabled]="headerData.isDeleted"
			ultra-menu-item
			[content]="'shared|record-header-menu|delete'"
			[iconClass]="'text-red-600'"
			[svgIcon]="'delete'"
			(click)="onDeleteClicked.emit()"></button>
	</span>
</div>
