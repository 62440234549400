<ng-container *ngIf="showTrailing">
	<ng-content></ng-content>
</ng-container>

<div [matTooltip]="tooltip" class="flex items-center">
	<mat-icon
		[ngClass]="iconClass"
		*ngIf="svgIcon"
		class="icon-size-5 mr-1.5"
		[svgIcon]="svgIcon"></mat-icon>
	<span *ngIf="content" class="text-md truncate">
		{{ content | transloco }}</span
	>
</div>
